import type { AnchorDetailStore } from '@/pages/anchorDetail/anchorDetailStore';
import {
  FLOW_TYPE,
  JOB_TYPE,
  LIVE_SHAPE,
  PART_SALARY_SETTLE_TYPE,
  PREPARE_TYPE,
  SEX,
  TRY_USE_SALARY_DISCOUNT,
  formatBooleanLabel,
} from '@/pages/anchorDetail/constants';
import { observer } from 'mobx-react';
import type React from 'react';

export const SubscribeInformation = observer(({ anchorDetailStore }: { anchorDetailStore: AnchorDetailStore }) => {
  const { anchorDetail } = anchorDetailStore;
  const userJobHuntVO = anchorDetail?.userJobHuntVO || {};
  const userAccountVO = anchorDetail?.userAccountVO || {};
  return (
    <div className={'flex flex-col gap-3'}>
      <ItemContainer
        title={'免费订阅信息'}
        data={[
          { title: '工作城市', content: userJobHuntVO?.cityName, required: true },
          {
            title: '直播类目',
            content: userJobHuntVO?.expectLiveTypeList
              ?.map((item) =>
                item?.childrenList?.length > 0 ? item.childrenList.map((val) => val.name).join('、') : item.name,
              )
              .join('、'),
            required: true,
          },
          {
            title: '岗位类型',
            content: Object.values(JOB_TYPE).find((item) => item.value === userJobHuntVO.jobType)?.label || '全部',
            required: true,
          },
          {
            title: '每日直播时长',
            content: `${userJobHuntVO.totalLiveHourStart || ''}-${userJobHuntVO.totalLiveHourEnd || ''}`,
          },
          {
            title: '期望薪资',
            content: `${userJobHuntVO.expectSalaryStart || ''}-${userJobHuntVO.expectSalaryEnd || ''}`,
          },
          {
            title: '提成',
            content: formatBooleanLabel(userJobHuntVO.salaryReward, '有', '无'),
          },
          {
            title: '性别要求',
            content: Object.values(SEX).find((item) => item.value === anchorDetail.sex)?.label,
          },
        ]}
      />
      {userAccountVO.newbieSubscribeVip ? (
        <ItemContainer
          title={'小白付费订阅信息'}
          data={[
            {
              title: '接受小白',
              content: formatBooleanLabel(userJobHuntVO.acceptNewbie, '接受', '不接受'),
            },
            {
              title: '坐班',
              content: formatBooleanLabel(userJobHuntVO.newbieIsKeepOffice, '需要', '不需要'),
            },
            {
              title: '排班方式',
              content:
                Object.values(PREPARE_TYPE).find((item) => item.value === userJobHuntVO.prepareType)?.label || '不限',
            },
            {
              title: '每月休息天数',
              content: `${userJobHuntVO.restDayStart || ''}-${userJobHuntVO.restDayEnd || ''}天`,
            },
            {
              title: '直播方式',
              content:
                Object.values(LIVE_SHAPE).find((item) => item.value === userJobHuntVO.newbieLiveShape)?.label || '不限',
            },
            {
              title: '试岗',
              content: formatBooleanLabel(userJobHuntVO.hasTryPost),
            },
            { title: '试岗薪酬', content: formatBooleanLabel(userJobHuntVO.tryPostHasSalary) },
            { title: '试用期', content: formatBooleanLabel(userJobHuntVO.hasTryUse) },
            {
              title: '试用时长',
              content: `${userJobHuntVO.tryUseTimeStart || ''}-${userJobHuntVO.tryUseTimeEnd || ''}月`,
            },
            {
              title: '试用期薪酬',
              content:
                Object.values(TRY_USE_SALARY_DISCOUNT).find((item) => item.value === userJobHuntVO.tryUseSalaryDiscount)
                  ?.label || '不限',
            },
            { title: '试用期提成', content: formatBooleanLabel(userJobHuntVO.tryUseSalaryReward) },
            { title: '社保', content: formatBooleanLabel(userJobHuntVO.newbieSocialSecurity) },
            { title: '其他福利', content: formatBooleanLabel(userJobHuntVO.otherWelfare) },
            { title: '露脸', content: formatBooleanLabel(userJobHuntVO.newbieShowFace) },
          ]}
        />
      ) : null}
      {userAccountVO.partSubscribeVip ? (
        <ItemContainer
          title={'兼职付费订阅信息'}
          data={[
            { title: '接受小白', content: formatBooleanLabel(userJobHuntVO.partAcceptNewbie, '接受', '不接受') },
            {
              title: '直播间流量',
              content: Object.values(FLOW_TYPE).find((item) => item.value === userJobHuntVO.flowType)?.label || '不限',
            },
            {
              title: '兼职薪酬',
              content: `${userJobHuntVO.expectPtSalaryStart || ''}-${userJobHuntVO.expectPtSalaryEnd || ''}/小时`,
            },
            { title: '兼职提成', content: formatBooleanLabel(userJobHuntVO.partSalaryReward, '有', '无') },
            {
              title: '兼职工资结算',
              content:
                Object.values(PART_SALARY_SETTLE_TYPE).find((item) => item.value === userJobHuntVO.partSalarySettleType)
                  ?.label || '不限',
            },
            { title: '补贴', content: formatBooleanLabel(userJobHuntVO.subsidy, '有', '无') },
            { title: '过往业绩', content: formatBooleanLabel(userJobHuntVO.pastPerformance, '有', '无') },
            {
              title: '单场直播时长',
              content: `${userJobHuntVO.liveHourStart || ''}-${userJobHuntVO.liveHourEnd || ''}小时`,
            },
            {
              title: '直播形式',
              content:
                Object.values(LIVE_SHAPE).find((item) => item.value === userJobHuntVO.partLiveShape)?.label || '不限',
            },
            { title: '露脸', content: formatBooleanLabel(userJobHuntVO.partShowFace) },
          ]}
        />
      ) : null}
    </div>
  );
});

function ItemContainer({
  title,
  data,
}: { title?: string; data: Array<{ title: string; content: React.ReactNode; required?: boolean }> }) {
  return (
    <div className={'p-4 border border-solid border-divide rounded-1 flex flex-col gap-4 min-w-0'}>
      {title ? <div className={'text-heading text-14 font-500'}>{title}</div> : null}
      <div className={'grid grid-cols-3 gap-4'}>
        {data.map((item, index) => (
          <div
            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
            key={index}
            className={'flex items-center justify-between text-12'}
          >
            {item.required ? <span className={'text-warning'}>*</span> : null}
            <span className={'text-normal mr-2'}>{item.title}:</span>
            <span className={'flex-1 text-heading truncate'}>{item.content}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
