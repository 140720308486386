import type { AnchorDetailStore } from '@/pages/anchorDetail/anchorDetailStore';
import type { ServiceOrderDetailProjectVO } from '@/pages/anchorDetail/types';
import type { ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';

export const ServiceInformation = observer(({ anchorDetailStore }: { anchorDetailStore: AnchorDetailStore }) => {
  const { anchorDetail } = anchorDetailStore;
  const columnDefs: ColDef<ServiceOrderDetailProjectVO>[] = [
    {
      field: 'projectName',
      headerName: '服务名称',
    },
    {
      field: 'amount',
      headerName: '支付金额(元)',
      cellRenderer: (params) => <span className={'text-error'}>{params.value || 0}</span>,
    },
    {
      field: 'validStartTime',
      headerName: '开始时间',
      valueFormatter: (params) => (params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      field: 'validEndTime',
      headerName: '到期时间',
      valueFormatter: (params) => (params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
    {
      field: 'createTime',
      headerName: '支付时间',
      valueFormatter: (params) => (params.value ? dayjs(params.value).format('YYYY-MM-DD HH:mm:ss') : ''),
    },
  ];

  return (
    <AgGridReact
      rowData={anchorDetail?.serviceOrderDetailProjectList || []}
      columnDefs={columnDefs}
      defaultColDef={{ flex: 1 }}
    />
  );
});
