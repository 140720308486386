import { message } from 'antd';

export const VIDEO_ACCEPT = '.mp4,.flv,.avi,.wmy,.mov,.webm,.mpeg4,.ts,.mpg,.rm,.rmvb,.mkv,.m4v';
export const IMAGE_ACCEPT = '.jpg,.jpeg,.png';

export function beforeUploadImage(file: Blob) {
  const isTypeMatch = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isTypeMatch) {
    const error = '仅支持上传JPG、JPEG、PNG!';
    message.error({ key: error, content: error });
    return false;
  }

  const isSizeMatch = file.size / 1024 / 1024 < 10;
  if (!isSizeMatch) {
    const error = '图片必须小于10MB!';
    message.error({ key: error, content: error });
    return false;
  }

  return true;
}

export function beforeUploadVita(file: Blob) {
  const isTypeMatch = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ].includes(file.type);
  if (!isTypeMatch) {
    const error = '请上传pdf、doc、docx文件';
    message.error({ key: error, content: error });
    return false;
  }

  const isSizeMatch = file.size / 1024 / 1024 < 10;
  if (!isSizeMatch) {
    const error = 'pdf必须小于10MB!';
    message.error({ key: error, content: error });
    return false;
  }

  return true;
}

export function beforeUploadVideo(file: Blob) {
  const isTypeMatch = [
    'video/mp4',
    'video/x-flv',
    'video/avi',
    'video/x-ms-wmv',
    'video/quicktime',
    'video/webm',
    'video/mpeg',
    'video/MP2T',
    'video/mpg',
    'application/vnd.rn-realmedia',
    'application/vnd.rn-realmedia-vbr',
    'video/x-matroska',
    'video/x-m4v',
  ].includes(file.type);

  if (!isTypeMatch) {
    const error = '仅支持上传 mp4,flv,avi,wmy,mov,webm,mpeg4,ts,mpg,rm,rmvb,mkv,m4v 格式!';
    message.error({ key: error, content: error });
    return false;
  }

  const isSizeMatch = file.size / 1024 / 1024 <= 100;
  if (!isSizeMatch) {
    const error = '视频必须小于100MB!';
    message.error({ key: error, content: error });
    return false;
  }

  return true;
}
