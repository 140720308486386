import { ASSETS } from '@/assets';
import { VideoModal } from '@/compnents/videoModal';
import { type AnchorDetailProps, AnchorDetailStore } from '@/pages/anchorDetail/anchorDetailStore';
import { AnchorImg, AnchorVideo } from '@/pages/anchorDetail/anchorImgAndVideo';
import { AuthModal } from '@/pages/anchorDetail/authModal';
import { ACCOUNT_STATUS, RIGHT_TABS, TAG_CATEGORY_TYPE } from '@/pages/anchorDetail/constants';
import { PlatformAccountModal } from '@/pages/anchorDetail/platformAccountModal';
import { RecommendWordModal } from '@/pages/anchorDetail/recommendWordModal';
import { ResumeInformationDetail } from '@/pages/anchorDetail/resumeInformationDetail';
import { ResumeInformationEdit } from '@/pages/anchorDetail/resumeInformationEdit';
import { ServiceInformation } from '@/pages/anchorDetail/serviceInformation';
import { SubscribeInformation } from '@/pages/anchorDetail/subscribeInformation';
import { TagModal } from '@/pages/anchorDetail/tagModal';
import { RenderByPermission } from '@/utils';
import { Button, Tag } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import { useParams } from 'react-router-dom';
import ModelCard from '../section/sectionAdmin/components/modelCard';

export default observer(() => {
  const params = useParams();
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const anchorDetailStore = React.useMemo(() => new AnchorDetailStore(params.id), []);
  React.useEffect(() => {
    anchorDetailStore.getAnchorDetail();
    anchorDetailStore.getLiveData();
    anchorDetailStore.getDictionary();
  }, [anchorDetailStore]);
  return (
    <>
      <div className={'flex gap-3 bg-bg min-h-screen'}>
        <LeftBaseInformation anchorDetailStore={anchorDetailStore} />
        <RightWrapper anchorDetailStore={anchorDetailStore} />
      </div>
      <AuthModal
        vitaAuditReasonList={anchorDetailStore.vitaAuditReasonList}
        authModalStore={anchorDetailStore.authModalStore}
      />
      <RecommendWordModal recommendWordModalStore={anchorDetailStore.sliceAndImgStore.recommendWordModalStore} />
      <VideoModal videoModalStore={anchorDetailStore.videoModalStore} />
      <TagModal tagModalStore={anchorDetailStore.tagModalStore} />
      <PlatformAccountModal platformAccountModalStore={anchorDetailStore.platformAccountModalStore} />
      <ModelCard store={anchorDetailStore.myModelCardStore} />
    </>
  );
});

const LeftBaseInformation = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const { anchorDetail } = anchorDetailStore;
  const serviceObjectList = anchorDetail?.serviceObjectList || [];
  return (
    <div className={'w-[448px] flex flex-col gap-3'}>
      <div className={'p-4 bg-white rounded-1 flex gap-6'}>
        <div className={'relative inline-block'}>
          <img
            className={'size-[106px] rounded-[106px]'}
            src={anchorDetail.avatar}
            alt=""
          />
          {serviceObjectList.length > 0 ? (
            <img
              className={'w-[52px] h-[22px] absolute left-1/2 bottom-0 ml-[-26px] mt-[11px]'}
              src={ASSETS.COMMON.PAY_STATUS}
              alt=""
            />
          ) : null}
        </div>
        <div className={'flex-1 min-w-0'}>
          <div className={'text-heading text-20 font-500 truncate leading-30'}>{anchorDetail.name}</div>
          <div className={'text-normal text-14 leading-21'}>{anchorDetail.mobile}</div>
          <div className={'mt-4 flex items-center text-heading text-14'}>
            <span>账号状态:</span>
            <span
              className={`size-[6px] rounded-2 mx-2 ${anchorDetail.accountStatus === ACCOUNT_STATUS.normal.value ? 'bg-success' : 'bg-error'}`}
            />
            <span>
              {Object.values(ACCOUNT_STATUS).find((item) => item.value === anchorDetail.accountStatus)?.label}
            </span>
          </div>
          <div className={'mt-4 flex items-center text-heading text-14'}>
            <span className={'mr-2'}>激活状态:</span>
            <span>{anchorDetail.isVirtual === 0 ? '已激活' : '未激活'}</span>
          </div>
        </div>
      </div>
      <BaseInformationContainer
        leftClassName={'w-18'}
        data={[
          { title: '注册来源', content: anchorDetail.channelName },
          {
            title: '注册时间',
            content: anchorDetail.createTime ? dayjs(anchorDetail.createTime).format('YYYY-MM-DD HH:mm:ss') : '',
          },
          {
            title: '注册推荐人',
            content: anchorDetail.inviteName,
          },
        ]}
      />
      <BaseInformationContainer
        leftClassName={'w-24'}
        title={'服务信息'}
        data={
          serviceObjectList.length > 0
            ? serviceObjectList.reduce((prev, current) => {
                prev.push({ title: '服务类型', content: current.serviceName });
                prev.push({
                  title: '服务到期时间',
                  content: current.validEndTime ? dayjs(current.validEndTime).format('YYYY-MM-DD HH:mm:ss') : '',
                });
                return prev;
              }, [])
            : [{ title: '服务类型', content: '免费服务' }]
        }
      />
      <BaseInformationContainer
        leftClassName={'w-18'}
        title={'求职数据'}
        data={[
          { title: '被邀约数', content: anchorDetail.invitedCount || 0 },
          { title: '报名数', content: anchorDetail.signUpCount || 0 },
          { title: '约面数', content: anchorDetail.interviewCount || 0 },
          { title: '到面数', content: anchorDetail.interviewedCount || 0 },
        ]}
      />
      {/* <BaseInformationContainer
        leftClassName={'w-14'}
        title={'绑定信息'}
        data={[
          { title: '经纪人', content: anchorDetail.brokerName },
          { title: '媒介', content: anchorDetail.mediumName },
          { title: '推荐人', content: anchorDetail.inviteName },
        ]}
      /> */}
      <AnchorTag anchorDetailStore={anchorDetailStore} />
    </div>
  );
});

const RightWrapper = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const { activeTabs, setActiveTabs, toggleIsEdit, isEdit, handleAuthAllResume } = anchorDetailStore;
  return (
    <div className={'flex-1 bg-white flex flex-col'}>
      <div className={'flex justify-between items-center h-12 px-8 border-b border-solid border-divide'}>
        <div className={'h-full flex justify-center items-center gap-8'}>
          {Object.values(RIGHT_TABS).map((item) => (
            <div
              key={item.value}
              className={`cursor-pointer transition-all h-full flex items-center text-20 border-b-2 border-solid mt-[1px] ${activeTabs === item.value ? 'text-primary border-primary' : 'text-heading border-transparent'}`}
              onClick={() => setActiveTabs(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className={'h-full flex justify-center items-center gap-2'}>
          {(() => {
            if (activeTabs !== RIGHT_TABS.resume.value) {
              return null;
            }

            if (isEdit) {
              return (
                <Button
                  onClick={toggleIsEdit}
                  type={'default'}
                >
                  返回查看
                </Button>
              );
            }

            return (
              <>
                <RenderByPermission permissionId={'67_59'}>
                  <Button
                    onClick={toggleIsEdit}
                    type={'link'}
                  >
                    编辑简历
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={'67_58'}>
                  <Button
                    onClick={handleAuthAllResume}
                    type={'link'}
                  >
                    审核完整简历
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={'67_84'}>
                  <Button
                    onClick={() => {
                      anchorDetailStore.onModelCardDownload(anchorDetailStore.anchorDetail);
                    }}
                    type={'link'}
                  >
                    下载模卡
                  </Button>
                </RenderByPermission>
              </>
            );
          })()}
        </div>
      </div>
      <div className={'p-4 flex-1'}>
        {activeTabs === RIGHT_TABS.resume.value && (
          <div className={'flex gap-4'}>
            {isEdit ? (
              <ResumeInformationEdit anchorDetailStore={anchorDetailStore} />
            ) : (
              <ResumeInformationDetail anchorDetailStore={anchorDetailStore} />
            )}

            <div className={'flex-1 flex flex-col gap-3'}>
              <AnchorVideo anchorDetailStore={anchorDetailStore} />
              <AnchorImg anchorDetailStore={anchorDetailStore} />
            </div>
          </div>
        )}
        {activeTabs === RIGHT_TABS.service.value && <ServiceInformation anchorDetailStore={anchorDetailStore} />}
        {activeTabs === RIGHT_TABS.subscribe.value && <SubscribeInformation anchorDetailStore={anchorDetailStore} />}
      </div>
    </div>
  );
});

const AnchorTag = observer(({ anchorDetailStore: { anchorDetail, handleAddTag } }: AnchorDetailProps) => {
  return (
    <div className={'p-4 bg-white rounded-1 flex flex-col gap-4 min-w-0'}>
      <div className={'text-heading text-14 font-500'}>主播标签</div>
      <div className={'flex flex-wrap gap-1 items-start'}>
        {(anchorDetail.tagList || []).map((item) => (
          <Tag
            color={'default'}
            key={item.libId}
          >
            {item.libName}
          </Tag>
        ))}
        <RenderByPermission permissionId={'67_45'}>
          <Tag
            onClick={() =>
              handleAddTag(
                anchorDetail?.tagList?.map((item) => item.libId) || [],
                [anchorDetail.id],
                TAG_CATEGORY_TYPE.anchor.value,
              )
            }
            color={'default'}
            className={'border-dashed'}
          >
            +
          </Tag>
        </RenderByPermission>
      </div>
    </div>
  );
});

function BaseInformationContainer({
  title,
  data,
  leftClassName,
}: {
  title?: string;
  leftClassName?: string;
  data: Array<{ title: string; content: React.ReactNode }>;
}) {
  return (
    <div className={'p-4 bg-white rounded-1 flex flex-col gap-4 min-w-0'}>
      {title ? <div className={'text-heading text-14 font-500'}>{title}</div> : null}
      {data.map((item, index) => (
        <div
          // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
          key={index}
          className={'flex items-center justify-between gap-2 text-14 text-heading'}
        >
          <span className={leftClassName}>{item.title}:</span>
          <span className={'flex-1 truncate'}>{item.content}</span>
        </div>
      ))}
    </div>
  );
}
