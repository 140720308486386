import { ASSETS } from '@/assets';
import { ACCOUNT_TYPE } from '@/pages/anchorDetail/constants';
import type { Platform } from '@/pages/anchorDetail/types';
import { type BaseData, type DictItem, request } from '@/utils';
import { Button, Drawer, Form, type FormInstance, Input, Radio, Space } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

interface Options {
  callback?: (params: { platformAccountInfo: Platform[] }, ...args: any[]) => Promise<any>;
  platformAccountInfo?: Platform[];
}

export class PlatformAccountModalStore {
  public formRef = React.createRef<FormInstance>();

  @observable public platformTypeList: { value: number; label: string }[] = [];
  private getDictionary = async () => {
    if (this.platformTypeList.length) {
      return;
    }

    const info = await request<BaseData<Record<'platform_type', DictItem[]>>>({
      url: '/pbb/platform/anon/system/sysDict/getDictMap',
      method: 'post',
      data: { dictTypeList: ['platform_type'] },
    });
    this.platformTypeList = (info.data?.platform_type || []).map((item) => ({
      value: Number(item.dictValue),
      label: item.dictName,
    }));
  };

  @observable public open = false;
  @observable.ref options: Options = {};
  @action public handleOpen = (options: Options) => {
    this.open = true;
    this.options = options;
    this.getDictionary();
  };
  @action public handleClose = () => {
    this.open = false;
    this.options = {};
  };

  @observable public isSubmit = false;
  public handleSubmit = async () => {
    if (this.isSubmit) {
      return;
    }

    const values: { platformAccountInfo: Platform[] } = await this.formRef.current.validateFields();
    this.isSubmit = true;
    try {
      await this.options.callback({
        platformAccountInfo: (values.platformAccountInfo || []).map((item) => ({
          ...item,
          platformValue: this.platformTypeList.find((val) => val.value == item.id)?.label || '',
        })),
      });
      this.handleClose();
    } finally {
      this.isSubmit = false;
    }
  };
}

export const PlatformAccountModal = observer(
  ({
    platformAccountModalStore: { open, handleClose, handleSubmit, isSubmit, formRef, options, platformTypeList },
  }: { platformAccountModalStore: PlatformAccountModalStore }) => {
    return (
      <Drawer
        destroyOnClose={true}
        open={open}
        onClose={handleClose}
        maskClosable={false}
        width={900}
        title={'直播帐号'}
        closable={true}
        footer={
          <div className={'flex justify-end gap-2'}>
            <Button onClick={handleClose}>取消</Button>
            <Button
              loading={isSubmit}
              type={'primary'}
              onClick={handleSubmit}
            >
              确定
            </Button>
          </div>
        }
      >
        <Form
          ref={formRef}
          autoComplete="off"
          layout={'vertical'}
          initialValues={{ platformAccountInfo: options.platformAccountInfo || [] }}
        >
          <Form.List name="platformAccountInfo">
            {(fields, { add, remove }) => {
              return (
                <div className={'flex flex-col gap-3'}>
                  {fields?.map((field) => {
                    return (
                      <div
                        className={'rounded-1 border border-solid border-divide'}
                        key={field.key}
                      >
                        <div className={'flex justify-between items-center h-8 bg-bg px-3'}>
                          <span>直播帐号{field.name + 1}</span>
                          <img
                            onClick={() => remove(field.name)}
                            className={'size-5 cursor-pointer'}
                            src={ASSETS.ANCHOR_DETAIL_VITA_DELETE}
                            alt=""
                          />
                        </div>
                        <div className={'p-3'}>
                          <Form.Item
                            className={'mb-2 mt-2'}
                            label="选择平台"
                            name={[field.name, 'id']}
                            rules={[{ required: true, message: '请选择平台' }]}
                          >
                            <Radio.Group options={platformTypeList} />
                          </Form.Item>

                          <Form.Item className={'mb-0'}>
                            <Form.List name={[field.name, 'account']}>
                              {(subFields, subOpt) => (
                                <div className={'flex flex-col gap-3'}>
                                  {subFields?.map((subField) => (
                                    <Space key={subField.key}>
                                      <Form.Item
                                        noStyle={true}
                                        name={[subField.name, 'account']}
                                      >
                                        <Input
                                          allowClear={true}
                                          placeholder={`请输入直播间号${subField.name + 1}`}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        noStyle={true}
                                        name={[subField.name, 'accountType']}
                                      >
                                        <Radio.Group options={Object.values(ACCOUNT_TYPE)} />
                                      </Form.Item>

                                      <img
                                        onClick={() => {
                                          subOpt.remove(subField.name);
                                        }}
                                        className={'size-5 cursor-pointer'}
                                        src={ASSETS.ANCHOR_DETAIL_VITA_DELETE}
                                        alt=""
                                      />
                                    </Space>
                                  ))}
                                  <Button
                                    type="dashed"
                                    onClick={() => subOpt.add({})}
                                    block={true}
                                  >
                                    增加账户
                                  </Button>
                                </div>
                              )}
                            </Form.List>
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })}

                  <Button
                    type="dashed"
                    onClick={() => add({})}
                    block={true}
                  >
                    新增直播账户
                  </Button>
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Drawer>
    );
  },
);
