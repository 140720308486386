import { ASSETS } from '@/assets';

export const ACCOUNT_STATUS = {
  normal: {
    value: 0,
    label: '正常',
  },
  forbidden: {
    value: 1,
    label: '禁用',
  },
};

export const JOB_TYPE = {
  fullTime: { value: 1, label: '全职' },
  partTime: { value: 2, label: '兼职' },
};

export const SEX = {
  male: { value: 1, label: '男' },
  female: { value: 2, label: '女' },
};

export const PREPARE_TYPE = {
  fixed: { value: 1, label: '固定' },
  turn: { value: 2, label: '轮班' },
};

export const LIVE_SHAPE = {
  stand: { value: 1, label: '站播' },
  seat: { value: 2, label: '坐播' },
};
export const TRY_USE_SALARY_DISCOUNT = {
  noDiscount: { value: 0, label: '不打折' },
  discount: { value: 1, label: '打折' },
  unknown: { value: 2, label: '面议' },
};

export const FLOW_TYPE = {
  free: { value: 1, label: '自然流' },
  unFree: { value: 2, label: '付费流量' },
};

export const PART_SALARY_SETTLE_TYPE = {
  day: { value: 1, label: '日结' },
  week: { value: 2, label: '周结' },
  month: { value: 3, label: '月结' },
};

export const VITA_AUDIT_STATUS = {
  audit: { value: 0, label: '待审核', img: '' },
  pass: { value: 1, label: '审核通过', img: ASSETS.ANCHOR_DETAIL_AUTH_PASS },
  refuse: { value: 2, label: '审核拒绝', img: ASSETS.ANCHOR_DETAIL_AUTH_REFUSE },
};

export const ACCOUNT_TYPE = {
  maturation: {
    value: 1,
    label: '成熟号',
  },
  newer: {
    value: 2,
    label: '新建号',
  },
  oldAccountRestart: {
    value: 3,
    label: '老号重启',
  },
};

export const JOB_STATUS = {
  workAtAnyTime: {
    value: 1,
    label: '离职随时到岗',
  },
  onDutyWithinTheMonthOfEmployment: {
    value: 2,
    label: '在职月内到岗',
  },
  considerOpportunitiesWhileEmployed: {
    value: 3,
    label: '在职考虑机会',
  },
  notConsidered: {
    value: 4,
    label: '在职暂不考虑',
  },
};

export const FILE_TYPE = {
  image: { value: 1, label: '图片' },
  video: { value: 2, label: '视频' },
  vita: { value: 3, label: '简历' },
} as const;

export const OPUS_TYPE = {
  image: { value: 1, label: '形象照' },
  works: { value: 2, label: '作品' },
} as const;

export const TAG_CATEGORY_TYPE = {
  anchor: { value: 5, label: '主播' },
  slice: { value: 2, label: '切片' },
};

export function formatBooleanLabel(value: number, yesText = '是', noText = '否', defaultText = '不限') {
  if (value == 1) {
    return yesText;
  } else if (value === 0) {
    return noText;
  } else {
    return defaultText;
  }
}

export const RIGHT_TABS = {
  resume: { value: 'resume', label: '简历信息' },
  service: { value: 'service', label: '服务信息' },
  subscribe: { value: 'subscribe', label: '订阅信息' },
};
