import { ASSETS } from '@/assets';
import type { AnchorDetailProps } from '@/pages/anchorDetail/anchorDetailStore';
import { beforeUploadVita } from '@/pages/anchorDetail/imgAndVideoUploadHelper';
import { RenderByPermission } from '@/utils';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import fileSaver from 'file-saver';
import { observer } from 'mobx-react';
import type React from 'react';

export const ResumeVita = observer(({ anchorDetailStore }: AnchorDetailProps) => {
  const {
    anchorDetail,
    isEdit,
    vitaStore: { isUploadVita, handleUploadVita, handleDeleteVita },
  } = anchorDetailStore;
  return (
    <div className={'p-4 border border-solid border-divide rounded-1'}>
      <div className={'text-heading text-14 font-500 mb-3'}>简历附件</div>
      {isEdit && (
        <div className={'mb-3'}>
          <Upload
            fileList={[]}
            multiple={true}
            maxCount={2}
            style={{ width: '100%' }}
            accept={'.pdf,.doc,.docx'}
            showUploadList={false}
            beforeUpload={beforeUploadVita}
            customRequest={handleUploadVita}
          >
            <Button loading={isUploadVita}>
              <UploadOutlined />
              上传简历附件
            </Button>
          </Upload>
        </div>
      )}
      <div className={'flex flex-col gap-2'}>
        {anchorDetail.vitaList?.map((item) => (
          <div
            className={'flex items-center gap-1 bg-bg p-2 rounded-1'}
            key={item.id}
          >
            <img
              className={'size-9'}
              src={ASSETS.COMMON.FILE_PDF}
              alt={''}
            />
            <div className={'text-normal text-12 flex-1 truncate'}>{item.title}</div>
            {isEdit ? (
              <Button
                onClick={() => handleDeleteVita(item.id)}
                type={'text'}
              >
                <img
                  className={'size-4'}
                  src={ASSETS.ANCHOR_DETAIL_VITA_DELETE}
                  alt=""
                />
              </Button>
            ) : (
              <RenderByPermission permissionId={'67_57'}>
                <Button
                  type={'text'}
                  onClick={() => fileSaver.saveAs(item.url)}
                >
                  <DownloadOutlined className={'text-14'} />
                </Button>
              </RenderByPermission>
            )}
          </div>
        ))}
      </div>
    </div>
  );
});
